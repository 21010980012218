<template>
    <header>
        <router-link to="/">
            <div class="header__identifier">
                <div class="header__identifier-name">DM</div>
            </div>
        </router-link>
        <NavSection />
    </header>
</template>

<script>

    import NavSection from '@/components/NavSection.vue';

    export default {

        name: 'HeaderBar',

        components: {
            NavSection,
        },

    }

</script>

<style>

    :root {
        --header-stripe-height: 10px;
    }

</style>

<style scoped>

    header {
        position: relative;
        padding-top: var(--header-stripe-height);
        background-color: var(--brand-color-accent);
    }

    .header__identifier {
        display: flex;
        align-items: center;
        position: absolute;
        top: var(--header-stripe-height);
        bottom: 0;
        left: calc(50% - calc(var(--max-section-width) / 2));
        padding: 0 var(--padding-reduced);
        background-color: var(--brand-color-accent);
        color: var(--text-color-brand);
        font-family: var(--font-family-heading);
        font-size: 2em;
    }

    @media only screen and (max-width: 950px) {

        .header__identifier {
            left: var(--padding-standard);
        }

    }

    @media only screen and (max-width: 600px) {

        .header__identifier {
            font-size: 1em;
        }

    }

    @media only screen and (max-width: 550px) {

        .header__identifier {
            left: var(--padding-reduced);
        }

    }

    @media only screen and (max-width: 475px) {

        .header__identifier {
            display: none;
        }

    }

</style>