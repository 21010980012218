<template>
    <HeaderBar />
    <router-view />
</template>

<script>

    import HeaderBar from '@/components/HeaderBar.vue';

    export default {

        components: {
            HeaderBar,
        },

        created() {

            document.addEventListener('click', () => {
                this.$bus.emit('document-clicked');
            });

        },

    }

</script>

<style>

    @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@100;300;400;900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@700&display=swap');


    :root {
        --brand-color: rgb(156, 49, 98);
        --brand-color-accent: rgb(146, 27, 81);
        --brand-color-accent-darker: rgb(98, 41, 66);
        --brand-color-translucent: rgba(156, 49, 98, 0.5);
        --background-color-shade: rgba(0, 0, 0, 0.65);
        --background-color-shade-light: rgba(0, 0, 0, 0.15);
        --background-plain: #fefefe;
        --text-color-plain: #666;
        --text-color-brand: rgba(255, 255, 255, 0.8);
        --background-alt-light: #f6f6f6;
        --text-color-alt-dark: rgba(0, 0, 0, 0.65);
        --divider-color-light: #ddd;
        --font-size-base: 20px;
        --font-weight-light: 300;
        --font-weight-mid: 400;
        --font-weight-heavy: 700;
        --line-height-base: 1.5em;
        --font-family-base: 'Alegreya Sans', sans-serif;
        --font-family-heading: 'Alegreya', serif;
        --padding-increased: 75px;
        --padding-standard: 50px;
        --padding-reduced: 25px;
        --padding-minimal: 10px;
        --max-section-width: 850px;
        --box-shadow-default: 0 0 0.5em 0.5em rgba(0, 0, 0, 0.1);
        --corner-radius-standard: 15px;
        --corner-radius-minimal: 5px;
    }

    * {
        box-sizing: border-box;
    }

    html {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    body {
        margin: 0;
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-light);
        line-height: var(--line-height-base);
        font-family: var(--font-family-base);
        background-color: var(--background-plain);
        color: var(--text-color-plain);
        text-align: justify;
    }

    header, footer, section {
        display: block;
    }

    h1, h2 {
        margin-top: 0;
        line-height: 1em;
        font-family: var(--font-family-heading);
        font-weight: var(--font-weight-heavy);
    }

    h1 {
        text-align: left;
        font-size: 2.5em;
        margin: 0 0 var(--padding-standard) 0;
    }

    a {
        text-decoration: none;
        outline: 0;
        color: var(--text-color-plain);
        border-bottom: 1px dotted var(--text-color-plain);
    }

    a:active, a:hover {
        outline: 0;
    }

    hr {
        margin: var(--padding-standard) 0;
        border: none;
        border-bottom: 1px dashed var(--divider-color-light);
    }

    section {
        padding: calc(var(--padding-standard) * 1.5) var(--padding-standard);
    }

    section:not(:first-of-type) {
        padding-top: 0;
    }

    section .section__inner {
        margin: 0 auto;
        width: 100%;
        max-width: var(--max-section-width);
    }

    section h2 {
        margin: 0;
    }

    section p:first-of-type {
        margin-top: 0;
    }

    section p:last-of-type {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {

        section {
            padding: var(--padding-standard) calc(var(--padding-standard) / 2);
        }

    }

</style>
