<template>
        <nav>
            <section>
                <div class="section__inner">
                    <router-link to="/">About</router-link>
                    &#10209;
                    <router-link to="/work-with-me">Work with me</router-link>
                    &#10209;
                    <router-link to="/books">Books</router-link>
                </div>
            </section>
        </nav>
</template>

<script>

    export default {

        name: 'NavSection',

    }

</script>

<style scoped>

    section {
        padding: 0;
    }

    nav {
        background-color: var(--background-alt-light);
        color: var(--text-color-alt-dark);
        text-align: right;
    }

    a {
        display: inline-block;
        color: var(--text-color-alt-dark);
        font-weight: var(--font-weight-mid);
        padding: var(--padding-minimal);
        border: none;
        font-size: 0.9em;
    }

    a:last-of-type {
        padding-right: var(--padding-standard);
    }

    a:hover,
    a.router-link-active {
        color: var(--brand-color);
    }

    @media only screen and (max-width: 550px) {

        a:last-of-type {
            padding-right: var(--padding-reduced);
        }

    }

    @media only screen and (max-width: 500px) {

        a {
            font-size: 0.8em;
        }

    }

    @media only screen and (max-width: 475px) {

        nav {
            text-align: center;
        }

        a:last-of-type {
            padding-right: var(--padding-minimal);
        }

    }

</style>