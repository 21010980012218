import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ './components/views/HomePage.vue')
    },
    {
        path: '/work-with-me',
        name: 'Work With Me',
        component: () => import(/* webpackChunkName: "work-with-me" */ './components/views/WorkWithMe.vue')
    },
    {
        path: '/books',
        name: 'Books',
        component: () => import(/* webpackChunkName: "books" */ './components/views/BookList.vue')
    },
    {
        path: "/:catchAll(.*)",
        component: () => import(/* webpackChunkName: "not-found" */ './components/views/NotFound.vue')
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {left: 0, top: 0};
        }
    },
});

export default router;
